.page.-edit-group {
  padding: 0 20px;

  section {
    &.actions {
      margin-bottom: 40px;
    }

    &.fields {
      & > * {
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
  }
}
