@import "../../../assets/scss/variables";

.button.-component {
  & > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    min-height: 48px;
    padding: 11px 20px 12px;
    border: none;
    border-radius: $border-radius-default;

    font-family: $font-family-default;
    font-size: 15px;
    line-height: 18px;
    font-weight: $font-weight-medium;
    letter-spacing: .2px;
    text-decoration: none !important;

    background: $color-primary;
    color: $color-text-white;
    cursor: pointer;
    transition: $transition-button;

    span {
      display: flex;
    }

    .icon-container {
      color: $color-text-white;

      & + * {
        margin-left: 8px;
      }
    }

    &:hover {
      background: $color-primary-highlight;
    }

    &:focus {
      background: $color-primary-highlight;
    }
  }

  .loader {
    color: $color-text-white;
  }

  &.-theme {
    &-default {
      .icon-container {
        color: $color-text-regular;
      }
    }

    &-danger {
      & > * {
        background: $color-danger;
        color: $color-text-regular;

        &:hover {
          background: darken($color-danger, 5%);
        }
      }
    }

    &-ghost {
      & > * {
        background: $color-page-bg-highlight;
        color: $color-text-regular;

        svg {
          color: $color-icon-faded;
        }

        &:hover {
          background: lighten($color-page-bg-highlight, 1%);
        }
      }
    }
  }

  &.-size {
    &-default {}

    &-small {
      & > * {
        padding: 10px 16px 11px;
        font-size: 13px;
        line-height: 16px;
        min-height: 40px;

        .icon-container {
          width: 20px;
          height: 20px;
          margin-left: -2px;

          & + * {
            margin-left: 8px;
          }
        }
      }
    }

    &-square {
      & > * {
        width: 40px;
        height: 40px;
        min-height: auto;
        padding: 0;
      }
    }
  }

  &.-full-width {
    & > * {
      width: 100%;
    }
  }

  &.-loading {
    & > * {
      position: relative;
      pointer-events: none;

      & > * {
        &:not(.loader) {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }

  &.-disabled {
    & > * {
      pointer-events: none;
      opacity: .5;
    }
  }
}
