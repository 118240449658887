@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.component.-mobile-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: $color-dark;
  padding: 0 20px;
  display: none;
  font-size: 13px;
  line-height: 16px;

  .nav {
    & > * {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  @include tablet {
    display: flex;
  }
}
