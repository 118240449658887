@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.component.-modal.-password_generator {
  section {
    &.field {
      position: relative;
      width: 400px;
      max-width: 100%;

      .icons {
        position: absolute;
        bottom: 12px;
        right: 24px;
        z-index: 1;
        display: flex;

        .icon-container {
          &:hover {
            color: $color-text-regular;
          }
        }

        .icon-container.-copy {
          svg {
            width: 18px;
          }
        }
      }

      input {
        box-shadow: none;
      }
    }

    &.options {
      margin: 35px 0 55px;

      .length-bar {
        margin-bottom: 25px;
      }

      .checkboxes {
        & > * {
          &:not(:last-child) {
            margin-right: 20px;
          }
        }

        @include tablet {
          font-size: 12px;
        }
      }
    }
  }
}
