.component.-modal-controller {
  &.-active {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 40px 0 0;
    background: rgba(0,0,0,.5);
    box-sizing: border-box;
    overflow: auto;

    & + .layout {
      filter: blur(2px);
    }
  }
}
