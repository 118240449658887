.page.-settings-security {
  & > section {
    &.security {
      & > * {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
}
