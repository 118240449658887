.flex,
.inline-flex {
  display: flex;
  &.center {
    justify-content: center;
    align-items: center;
  }
  &.column {
    flex-direction: column;
  }
  &.j-start {
    justify-content: flex-start;
  }
  &.j-center {
    justify-content: center;
  }
  &.j-between {
    justify-content: space-between;
  }
  &.j-end {
    justify-content: flex-end;
  }
  &.j-around {
    justify-content: space-around;
  }
  &.a-start {
    align-items: flex-start;
  }
  &.a-center {
    align-items: center;
  }
  &.a-end {
    align-items: flex-end;
  }
  &.a-stretch {
    align-items: stretch;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.grow,
  .grow {
    flex-grow: 1;
  }
  &.shrink,
  .shrink {
    flex-shrink: 0;
  }
}

.inline-flex {
  display: inline-flex;
}

.text {
  &-faded {
    color: $color-text-faded;
  }

  &-regular {
    color: $color-text-regular;
  }

  &-security-strong {
    color: $color-security-strong;
  }

  &-security-weak {
    color: $color-security-weak;
  }
}

form {
  & > * {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.buttons {
  display: flex;
  align-items: center;

  & > * {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.relative {
  position: relative;
}

.scroll-parent {
  position: relative;
  height: 100%;
  overflow: auto;
}

.separator {
  display: block;
  width: 100%;
  height: 1px;
  background: $color-content-bg;
  margin: 30px auto;
}

.icons {
  & > .icon-container {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.icon-container {
  display: inline-flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $color-text-faded;
  transition: $transition-default;
}

[data-tippy-root] {
  z-index: 10001 !important;

  .tippy-content {
    padding: 10px 15px;
    font-size: 12px;
    line-height: 16px;
  }

  .tippy-arrow {
    width: 12px;
    height: 12px;
    color: $color-dark;
  }

  .tippy-box {
    background: $color-dark;
    border-radius: $border-radius-default;
    box-shadow: $box-shadow-default;

    &[data-placement="top-end"] {
      transform: translate(12px, 0);

      .tippy-arrow {
        margin-left: -12px;

        &:before {
          bottom: -6px;
          border-width: 6px 6px 0;
        }
      }
    }

    &[data-placement="left"] {
      .tippy-arrow {
        &:before {
          border-width: 6px 6px 0 !important;
          transform: rotate(225deg);
          right: -14px !important;
          bottom: 0px !important;
        }
      }
    }

    //&[data-placement="top-start"] {
    //  .tippy-arrow {
    //    transform: translate3d(36px, 0px, 0px) !important;
    //    margin-left: -10px;
    //
    //    &:before {}
    //  }
    //}

    &[data-theme="small"] {
      border-radius: $border-radius-default;

      .tippy-content {
        padding: 8px 12px;
      }

      .tippy-arrow {
        &:before {
          border-width: 5px 6px 0;
          bottom: -5px;
        }
      }
    }
  }
}
