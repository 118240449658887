@import "src/assets/scss/variables";

.page.-landing {
  header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    margin-top: 30px;

    nav {
      margin-right: 20px;

      & > * {
        margin: 0 14px;
        padding: 10px;
        color: $color-text-regular;
      }
    }
  }

  .logo {
    width: 100px;
    margin-top: -6px;
    margin-right: 30px;

    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .laptop {
    position: relative;

    .screen {
      position: absolute;
      top: 28px;
      left: 84px;
      z-index: 1;
      width: 520px;
    }
  }

  section {
    &:not(.first) {
      padding: 80px 0;
    }

    &.first {
      min-height: 100vh;
      padding-top: 140px;
      padding-bottom: 80px;
      background: url('../../assets/img/stars_bg.svg') repeat center;

      .info {
        width: 400px;
        margin-right: 40px;

        h1 {
          font-size: 42px;
          line-height: 48px;
        }

        .text {
          margin: 24px auto 40px;
          font-size: 16px;
          line-height: 25px;
          color: $color-text-faded;
        }
      }
    }

    &.copyright {
      padding: 0 0 30px;
      min-height: auto;
      text-align: center;
      font-size: 13px;
      line-height: 16px;
      color: $color-text-faded;
    }
  }
}
