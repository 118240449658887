@import "src/assets/scss/variables";

.component.-item {
  width: 100%;
  margin-bottom: 10px;
  padding: 16px 16px;
  //background: $color-content-bg;
  border-radius: $border-radius-default;
  cursor: pointer;
  transition: $transition-default;

  .image {
    flex-shrink: 0;
    margin-right: 12px;
    transition: $transition-default;
    filter: grayscale(1);

    .letter {
      font-size: 15px;
    }
  }

  .content {
    margin-top: 1px;
    max-width: 100%;
    overflow: hidden;
  }

  .title {
    font-size: 15px;
    line-height: 18px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .subtitle {
    margin-top: 2px;
    font-size: 12px;
    line-height: 14px;
    max-height: 14px;
    overflow: hidden;
  }

  .fields {
    margin-top: 30px;

    & > * {
      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }
  }

  &:hover {
    box-shadow: $box-shadow-default;
    //background: $color-content-bg-highlight;
    background: $color-content-bg;

    .image {
      filter: grayscale(0);
    }
  }

  &.-active {
    background: $color-dark;
    color: $color-text-regular;
    position: sticky;
    z-index: 1;
    top: 0;
    bottom: 20px;

    .image {
      filter: grayscale(0);
    }
  }
}
