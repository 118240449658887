@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.component {
  &.-sub-aside {
    width: 28%;
    height: 100%;
    padding: 40px 0 60px; // 60px mobile-bar
    border-right: 1px solid $color-dark;
    flex-shrink: 0;

    & > * {
      height: 100%;
    }

    .aside-head {
      padding: 0 20px;
      margin-bottom: 40px;
    }

    .scroll-parent {
      padding: 0 20px;
    }
  }

  &.-sub-aside-item-list {
    //
  }

  &.-sub-aside-profile-menu {
    padding: 0 20px;

    a {
      color: $color-text-regular;

      &.active {
        color: $color-primary-light;
      }
    }
  }
}
