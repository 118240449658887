$color-primary: #195CDC;
$color-primary-light: #4783F4;
$color-primary-highlight: #0D4DC7;

$color-dark: #171717;
$color-danger: #bf1d4a;
$color-success: #85D79B;
$color-warning: #FDBF61;

$color-security-weak: $color-danger;
$color-security-strong: #0E9DB6;

$color-page-bg: #1F1F1F;
$color-page-bg-highlight: #242424;
$color-content-bg: #242424;
$color-content-bg-highlight: #262626;
$color-modal-bg: $color-page-bg;

$color-text-regular: #FBFBFB;
$color-text-light: rgba($color-text-regular, .5);
$color-text-faded: #7A7A7A;
$color-text-white: #fff;
$color-text-placeholder: $color-text-faded;

$color-border: #CEC7ED;
$color-icon-faded: #9E9E9E;

$color-shadow: rgba(#000, 0);
$color-shadow-highlight: rgba(#000, .15);
