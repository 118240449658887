.page.-auth {
  text-align: center;

  h1 {
    margin-bottom: 30px;
  }

  .subheading {
    margin-bottom: 10px;
  }
}
