@import "src/assets/scss/variables";

.page.-unlock {
  text-align: center;

  h1 {
    margin-bottom: 20px;
  }

  .subheading {
    margin-bottom: 10px;
  }

  form {
    margin: 35px 0 30px;
  }

  .bottom {
    font-size: 13px;
    line-height: 16px;
    color: $color-text-faded;

    .name {
      max-width: 110px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: middle;
    }

    a {
      font-weight: $font-weight-semibold;
    }
  }
}
