@import "src/assets/scss/variables";

.component.-password-field {
  position: relative;

  .icons {
    position: absolute;
    z-index: 1;
    bottom: 13px;
    right: 22px;
    user-select: none;
  }

  input {
    padding-right: 70px;
  }

  .icon-container {
    &:hover {
      color: $color-text-regular;
    }
  }
}
