@import "src/assets/scss/variables";

.component.-search-bar {
  position: relative;

  .icon-parent {
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    color: $color-text-faded;

    .icon.-search {
      margin-top: -2px;
    }

    .icon-container.-cross {
      width: 100%;
      height: 100%;
      cursor: pointer;

      &:hover {
        color: $color-text-regular;
      }
    }
  }

  input {
    padding-left: 30px !important;
  }
}
