@import "src/assets/scss/variables";

.page.-group {
  section {
    &.actions {
      margin-bottom: 40px;
    }

    &.head {
      margin-bottom: 20px;

      .image {
        width: 60px;
        height: 60px;
        margin-right: 20px;
        border-radius: $border-radius-default;
        background: $color-page-bg-highlight;
        overflow: hidden;
      }

      .name {
        font-size: 24px;
        line-height: 30px;
        font-weight: $font-weight-semibold;
      }

      .type {
        margin-top: 6px;
        font-size: 13px;
        line-height: 18px;
        color: $color-text-faded;
      }

      .star {
        width: 60px;
        height: 60px;
        cursor: pointer;

        svg {
          width: 24px;
          height: 24px;
        }

        &.-active {
          color: $color-warning;
        }
      }
    }

    &.fields {
      & > * {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }

  & > .intro {
    & > * {
      &:not(.fields) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .separator {
    width: calc(100% - 40px);
  }

  .note-section {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;

    .label {
      margin-bottom: 10px;
      font-size: 13px;
      line-height: 16px;
      color: $color-text-faded;
    }

    .content {
      word-break: break-word;
    }
  }
}
