@import "src/assets/scss/variables";

.component.-item-list {
  nav {
    margin-bottom: 20px;

    .nav-item {
      display: flex;
      align-items: center;
      padding: 10px 16px;
      color: $color-primary-light;

      svg {
        margin-right: 10px;
        color: inherit;
      }
    }
  }

  &.-empty {
    height: 100%;
    padding-bottom: 40px;

    & > * {
      height: 100%;
      font-size: 13px;
      line-height: 18px;
      color: $color-text-faded;
    }

    .button-parent {
      margin-top: 10px;
    }
  }

  &.-loading {
    height: 100%;
    padding-bottom: 40px;

    .loader-parent {
      position: relative;
      height: 100%;
    }
  }

  .sub-empty {
    //height: calc(100% - 40px);
    //font-size: 13px;
    //line-height: 18px;
    //color: $color-text-faded;
    margin-top: 60px;
  }
}
