@import "src/assets/scss/variables";

.component.-user-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 20px;
  font-size: 13px;
  line-height: 16px;
  color: $color-text-faded;
  text-decoration: none;
  transition: $transition-default;

  &:hover {
    background: darken($color-page-bg-highlight, 1%);
  }

  .photo {
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    margin-right: 15px;
    border-radius: 50%;
    background: $color-dark;
    color: $color-text-regular;
  }

  .name {
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 17px;
    line-height: 20px;
    color: $color-text-regular;
    margin-bottom: 4px;
  }

  &.active {
    background: $color-primary;
    color: $color-text-light;

    .photo {
      background: $color-primary-highlight;

      .icon-container {
        color: $color-text-regular;
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 20px;
    background-image: linear-gradient(180deg, rgba($color-content-bg, 0%), $color-content-bg 100%);
  }
}
