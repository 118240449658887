@import "../../../assets/scss/variables";
@import "../../../assets/scss/extends";

.input.-component {
  position: relative;

  label {
    display: block;
    margin-bottom: 10px;
    color: $color-text-faded;
    font-size: 13px;
    line-height: 16px;
  }

  textarea {
    @extend %ui-field;
    box-shadow: $box-shadow-default;

    &:focus {
      //border-color: $color-border;
      box-shadow: $box-shadow-default-highlight;
    }
  }

  .buttons {
    position: absolute;
    top: 12px;
    right: 20px;
    z-index: 1;
  }

  .icon-container.-info {
    color: $color-danger;
    cursor: none;
  }

  &.-theme {
    &-default {}

    &-clean {
      textarea {
        padding: 0;
        box-shadow: none;
        background: transparent;
        border: none;
        border-radius: 0;
      }
    }
  }

  &.-disabled,
  &.-readonly {
    .icon-error {
      cursor: default;
    }
  }
}
