@mixin font-face ($name, $style, $weight, $path, $svg-id) {
  @font-face {
    font-family: $name;
    font-style: $style;
    font-weight: $weight;
    src: url("#{$path}.woff2") format("woff2"),
    url("#{$path}.woff") format("woff"),
    url("#{$path}.svg##{$svg-id}") format("svg");
  }
}

$desktop: 1440px;
$main: 1280px;
$tablet: 1180px;

@mixin desktop {
  @media (max-width: $desktop) {
    @content;
  }
}

@mixin main {
  @media (max-width: $main) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet) {
    @content;
  }
}
