@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.component.-main-aside {
  position: relative;
  width: 22%;
  height: 100%;
  padding: 0 0 84px;
  background: $color-page-bg-highlight;
  border-right: 1px solid $color-dark;
  overflow: auto;
  flex-shrink: 0;

  .spacer {
    display: block;
    width: 100%;
    height: 20px;
    margin-bottom: 0 !important;

    &.-large {
      height: 40px;
    }
  }

  .scroll-parent {
    padding: 0 20px;
  }

  .sections {
    section {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }

  section {
    header {
      margin-bottom: 12px;
      font-size: 13px;
      line-height: 16px;
      color: $color-text-faded;
    }

    .on-hover {
      transition: $transition-aside-item;
      opacity: 0;
      visibility: hidden;
    }

    &:hover,
    &.-empty {
      .on-hover {
        transition: none;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  nav {
    display: block;

    & > * {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .right {
    & > a {
      text-decoration: none;
    }

    & > * {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  @include tablet {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;

    &.-shown {
      display: block;
    }
  }
}
