@import "../../../assets/scss/variables/index";

.component.-item-field {
  position: relative;
  border-radius: $border-radius-default;
  cursor: pointer;
  transition: $transition-default;
  user-select: none;

  .intro {
    padding: 20px 110px 26px 20px;
    border-radius: $border-radius-default;
  }

  .label {
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 16px;
    color: $color-text-faded;
  }

  .field {
    font-size: 16px;
    line-height: 20px;
    color: $color-text-regular;
    user-select: text;
  }

  .icons {
    position: absolute;
    top: 50%;
    right: 40px;
    z-index: 1;
    transform: translate(0, -50%);
    opacity: 0;
    visibility: hidden;
    transition: $transition-default;

    & > * {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    .icon-container {
      &.-eye {
        svg {
          width: 22px;
          height: 15px;
          transition: $transition-icon;
        }
      }

      &:hover {
        svg {
          color: $color-text-white;
        }
      }
    }
  }

  input, textarea {
    cursor: pointer;
  }

  input {
    text-overflow: ellipsis;
  }

  &.-non-interactive {
    cursor: default;

    input, textarea {
      cursor: text;
    }
  }

  &:not(.-non-interactive) {
    &:hover {
      background: $color-content-bg;

      .icons {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
