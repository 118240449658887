// app
@import 'variables/index';
@import "reset";
@import 'typography';
@import 'utils';
@import 'extends';

// dependencies
@import '/node_modules/tippy.js/dist/tippy.css';
@import '/node_modules/tippy.js/animations/perspective-subtle.css';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-default;
  font-size: $font-size-regular;
  line-height: $line-height-regular;
  font-weight: $font-weight-medium;
  background: $color-page-bg;
  color: $color-text-regular;
}

::selection {
  background: rgba($color-primary-light, .5);
  color: $color-text-regular;
  text-shadow: none;
}

::-webkit-input-placeholder { color: $color-text-placeholder; }
::-moz-placeholder { color: $color-text-placeholder; }
:-ms-input-placeholder { color: $color-text-placeholder; }

a {
  color: $color-primary-light;
  text-decoration: none;
  transition: $transition-link;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
  }
}

.app {
  height: 100vh;
}

.container {
  width: 680px;
  max-width: 100%;
  margin: 0 auto;

  &.-narrow {
    width: 330px;
  }

  &.-wide {
    width: 1200px;
  }
}
