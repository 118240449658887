@import "src/assets/scss/variables";

.page.-home {
  width: 100%;
  height: 100%;
  text-align: center;

  font-size: 13px;
  line-height: 18px;
  color: $color-text-faded;
}
