@import "src/assets/scss/variables";

.component.-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  user-select: none;
  color: $color-primary;
}
