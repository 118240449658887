@import "src/assets/scss/variables";

.component.-ui-checkbox {
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;

    &:checked {
      & + .box {
        .icon {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .box {
    width: 24px;
    height: 24px;
    background: $color-content-bg;
    border-radius: $border-radius-small;
    cursor: pointer;

    .icon {
      width: 12px;
      opacity: 0;
      visibility: hidden;
    }
  }

  .label {
    margin-left: 10px;
    cursor: pointer;
  }
}
